'use client'

import dynamic from 'next/dynamic'
import {
	type ReactNode,
	createContext,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react'

import { type SnackbarProps } from '../components/Snackbar/Snackbar'
import {
	type NavigationSnackbarProps,
	useNotificationsStorage,
} from '../hooks/useNotificationsStorage'

const Snackbar = dynamic(() =>
	import('../components/Snackbar/Snackbar').then((mod) => mod.Snackbar)
)

export interface NotificationsContextProps {
	snackbarShow: boolean
	setSnackbarShow: (show: boolean) => void
	addNotification: (props: SnackbarProps) => void
	addNotificationOnNavigation: (props: NavigationSnackbarProps) => void
	removeNotification: () => void
	Notification: ReactNode | null
}

interface NotificationsProviderProps {
	children: React.ReactNode
	snackbarShowDefault?: boolean
}

export const NotificationsContext =
	createContext<NotificationsContextProps | null>(null)

export const NotificationsProvider = ({
	children,
	snackbarShowDefault = false,
}: NotificationsProviderProps) => {
	const [snackbarShow, setSnackbarShow] = useState<boolean>(snackbarShowDefault)
	const [snackbarProps, setSnackbarProps] = useState<SnackbarProps | null>(null)
	const [notificationsQueue, setNotificationsQueue] = useState<SnackbarProps[]>(
		[]
	)
	const { addNotificationOnNavigation } = useNotificationsStorage(
		setNotificationsQueue
	)

	const firstNotificationQueue = notificationsQueue[0]
	const notificationsQueueSize = notificationsQueue.length

	const addNotification = useCallback((item: SnackbarProps) => {
		setNotificationsQueue((prevQueue) => [...prevQueue, item])
	}, [])

	const removeNotification = useCallback(() => {
		setNotificationsQueue((prevQueue) => prevQueue.slice(1))
	}, [])

	const showNotification = useCallback(() => {
		setSnackbarShow(true)
		setSnackbarProps({ ...firstNotificationQueue })
	}, [firstNotificationQueue])

	const Notification = snackbarProps ? <Snackbar {...snackbarProps} /> : null

	const values = useMemo(
		() => ({
			snackbarShow,
			setSnackbarShow,
			addNotification,
			addNotificationOnNavigation,
			removeNotification,
			Notification,
		}),
		[snackbarShow, snackbarProps]
	)

	useEffect(() => {
		if (notificationsQueueSize > 0) {
			showNotification()
		}
	}, [notificationsQueueSize])

	return (
		<NotificationsContext.Provider value={values}>
			{children}
		</NotificationsContext.Provider>
	)
}
