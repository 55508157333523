export function fetcher<T>(url: string, options: RequestInit = {}): Promise<T> {
	return fetch(url, { ...options, next: { revalidate: 0 } }).then((res) => {
		if (!res.ok) {
			return res.text().then((errorMessage) => {
				throw new Error(errorMessage)
			})
		}

		return res.json()
	})
}
